'use client'

import { Fragment, useEffect, useMemo, useState } from 'react'
import Slider from 'react-slick'

import { Box } from '@/components/box'
import { ObsoloteProductListItem } from '@/components/obsolote-product-list-item/obsolote-product-list-item'
import { ObsoloteProductListItemSkeleton } from '@/components/obsolote-product-list-item/obsolote-product-list-item-skeleton'
import {
  ProductListItemData,
  obsoleteIsConfigurableProductListItem,
  obsoleteIsGiftCardProductListItem,
} from '@/common/types/product-types'
import {
  ProductStockStatus,
  useLastViewedProductsQuery,
  useTopProductsQuery,
} from '@/api'
import { SKU_RECENTLY_VIEWED_PRODUCT } from '@/providers/product-data/utils'
import { selectors } from '@/common/constants/selectors-constants'
import { ProductDataContextProvider } from '@/providers/product-data/obsolote-product-data-context'
import { isServerMobile } from '@/common/utils'
import { getSliderSettings } from './slider-config'

export type HomepageProductsGridProps = {
  onPurchaseVariant: (product: ProductListItemData) => void
  baseUrl: string
}

export function HomepageProductsGrid({
  onPurchaseVariant,
  baseUrl,
}: HomepageProductsGridProps): JSX.Element {
  const [finalProducts, setFinalProducts] = useState<
    ProductListItemData[] | undefined
  >()
  const [activeSlide, setActiveSlide] = useState<number>(0)

  const lastViewedProductsSkus = useMemo(() => {
    try {
      return JSON.parse(
        localStorage.getItem(SKU_RECENTLY_VIEWED_PRODUCT) ?? '[]',
      ) as string[]
    } catch {
      return []
    }
  }, [])

  const topProductsQuery = useTopProductsQuery()
  const lastViewedProductsQuery = useLastViewedProductsQuery({
    skus: lastViewedProductsSkus.length > 0 ? lastViewedProductsSkus : '',
  })

  useEffect(() => {
    const getFinalProducts = async () => {
      const isMobile = await isServerMobile()
      const maxTopProducts = isMobile ? 6 : 12
      const maxLastViewedProducts = isMobile ? 4 : 6

      const topProductsItems =
        topProductsQuery.data?.categories?.items?.[0]?.products?.items

      type ProductItem = NonNullable<typeof topProductsItems>[number]

      const isConfigurableOrGiftCardProduct = (
        item: ProductItem,
      ): item is ProductItem & {
        stock_status: ProductStockStatus
        sku: string
      } => {
        return (
          item?.__typename === 'ConfigurableProduct' ||
          item?.__typename === 'GiftCardProduct'
        )
      }

      const topProductsItemsInStock = topProductsItems
        ?.filter((item) => {
          if (!isConfigurableOrGiftCardProduct(item)) {
            return false
          }
          const isInStock = item.stock_status === ProductStockStatus.InStock
          const isLastViewed = lastViewedProductsSkus.includes(item.sku)
          return isInStock && !isLastViewed
        })
        .slice(0, maxTopProducts)

      const lastViewedProducts = lastViewedProductsQuery.data?.products?.items
        ?.sort((a, b) => {
          if (
            isConfigurableOrGiftCardProduct(a) &&
            isConfigurableOrGiftCardProduct(b)
          ) {
            return (
              lastViewedProductsSkus.indexOf(a.sku ?? '') -
              lastViewedProductsSkus.indexOf(b.sku ?? '')
            )
          }
          return 0
        })
        .slice(0, maxLastViewedProducts)

      return [
        ...(lastViewedProducts || []),
        ...(topProductsItemsInStock || []),
        // max amount of products displayed, is equal to max top products.
      ].slice(0, maxTopProducts) as ProductListItemData[]
    }

    if (!topProductsQuery.isLoading && !lastViewedProductsQuery.isLoading) {
      getFinalProducts().then((finalProducts) => {
        setFinalProducts(finalProducts)
      })
    }
  }, [
    lastViewedProductsQuery.data,
    topProductsQuery.data,
    lastViewedProductsSkus,
    topProductsQuery.isLoading,
    lastViewedProductsQuery.isLoading,
  ])

  const settings = useMemo(
    () => getSliderSettings(activeSlide, setActiveSlide),
    [activeSlide],
  )

  return (
    <>
      <div className="hidden md:block lg:hidden">
        {finalProducts ? (
          <Slider {...settings}>
            {finalProducts.map((product) => (
              <div
                key={`product_hp_grid_${product.uid}`}
                className="min-h-[330px]"
              >
                <ObsoloteProductListItem product={product} baseUrl={baseUrl} />
              </div>
            ))}
          </Slider>
        ) : (
          <div className="flex justify-between gap-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <ObsoloteProductListItemSkeleton key={index} />
            ))}
          </div>
        )}
      </div>
      <Box
        className="w-full grid grid-cols-2 md:hidden lg:grid lg:grid-cols-6 gap-6 my-5"
        data-test={selectors.HP.bestsellers}
      >
        {!finalProducts
          ? Array.from({ length: 12 }).map((_, index) => (
              <ObsoloteProductListItemSkeleton key={index} />
            ))
          : finalProducts?.map((finalProduct) => {
              return (
                <ProductDataContextProvider
                  productSku={finalProduct.sku}
                  outOfStock={
                    finalProduct.stock_status === ProductStockStatus.OutOfStock
                  }
                  configurableVariants={
                    obsoleteIsConfigurableProductListItem(finalProduct)
                      ? finalProduct.configurable_variants
                      : undefined
                  }
                  giftCardAmounts={
                    obsoleteIsGiftCardProductListItem(finalProduct)
                      ? finalProduct.giftcard_amounts
                      : undefined
                  }
                  key={`product_hp_grid_${finalProduct.uid}`}
                >
                  <ObsoloteProductListItem
                    product={finalProduct}
                    onPurchase={onPurchaseVariant}
                    baseUrl={baseUrl}
                  />
                </ProductDataContextProvider>
              )
            })}
      </Box>
    </>
  )
}
