'use client'

import { useTranslations } from 'next-intl'

import {
  BlogPosts,
  CategoriesByGoalQuery,
  CategoryByGoal,
  NewestBlogPostsQuery,
} from '@/api'
import { Box } from '@/components/box'
import {
  BagIcon,
  HeartIcon,
  PackageIcon,
  SandWatchIcon,
} from '@/components/icons/svg'
import { BlogComponent } from './components/blog/blog-component'
import { CategoriesByGoal } from './components/categories-by-goal/categories-by-goal-component'
import { HomepageProducts } from './components/products/homepage-products'
import { USPAreaComponent } from './components/usp-area/usp-area-component'
import { useHomepageTracking } from './components/tracking/homepage-tracking'

export type HomepageClientProps = {
  categoriesByGoalQuery: CategoriesByGoalQuery
  newestBlogPostsQuery: NewestBlogPostsQuery
  baseUrl: string
}

export function HomepageClient({
  categoriesByGoalQuery,
  newestBlogPostsQuery,
  baseUrl,
}: HomepageClientProps): JSX.Element {
  const t = useTranslations('USP')
  const categoriesByGoalData = (categoriesByGoalQuery?.categoriesByGoal ||
    []) as CategoryByGoal[]
  const newestBlogPostsData = (newestBlogPostsQuery?.NewestBlogPosts ??
    {}) as BlogPosts

  useHomepageTracking()

  return (
    <div>
      <Box className="container max-w-screen-desktop mx-auto flex flex-col flex-1 px-5 pb-20 xl:px-0">
        <div className="my-6">
          <USPAreaComponent
            items={[HeartIcon, SandWatchIcon, PackageIcon, BagIcon].map(
              (icon, index) => {
                const idx = index + 1

                return {
                  icon,
                  title: t(`box${idx}.title`),
                  subtitle: t(`box${idx}.subtitle`),
                }
              },
            )}
          />
        </div>
        <div className="border-b-4 border-b-black lg:border-b-0 -mx-5 lg:mx-0" />
        {categoriesByGoalData.length > 0 && (
          <CategoriesByGoal categories={categoriesByGoalData} />
        )}
        <HomepageProducts baseUrl={baseUrl} />
        <BlogComponent posts={newestBlogPostsData} />
      </Box>
    </div>
  )
}
