'use client'

import { useCallback, useState } from 'react'

import {
  ProductListItemData,
  obsoleteIsConfigurableProductListItem,
  obsoleteIsGiftCardProductListItem,
} from '@/common/types/product-types'
import { ProductFlashMessages } from '@/modules/product/components/flash-messages/flash-messages-component'
import { HomepageProductsGrid } from './homepage-products-grid'
import { ProductStockStatus } from '@/api'
import { ObsoletePurchaseDialog } from '@/components/purchase-dialog/obsolete-purchase-dialog'
import { ProductDataContextProvider } from '@/providers/product-data/obsolote-product-data-context'
import { delayBeforeClosingProductModal } from '@/common/constants/products'

type Props = {
  baseUrl: string
}

export function HomepageProducts({ baseUrl }: Props): JSX.Element {
  const [dialogProductData, setDialogProductData] = useState<
    ProductListItemData | undefined
  >()
  const [isDialogVisible, setIsDialogVisible] = useState(false)

  const onCloseDialog = useCallback(() => {
    setTimeout(() => {
      setDialogProductData(undefined)
      setIsDialogVisible(false)
    }, delayBeforeClosingProductModal)
  }, [])

  const onPurchaseVariant = useCallback((product: ProductListItemData) => {
    setDialogProductData(product)
    setIsDialogVisible(true)
  }, [])

  return (
    <div className="relative">
      <ProductFlashMessages />
      <HomepageProductsGrid
        onPurchaseVariant={onPurchaseVariant}
        baseUrl={baseUrl}
      />
      {dialogProductData && (
        <ProductDataContextProvider
          productSku={dialogProductData.sku}
          outOfStock={
            dialogProductData.stock_status === ProductStockStatus.OutOfStock
          }
          configurableVariants={
            obsoleteIsConfigurableProductListItem(dialogProductData)
              ? dialogProductData.configurable_variants
              : undefined
          }
          giftCardAmounts={
            obsoleteIsGiftCardProductListItem(dialogProductData)
              ? dialogProductData.giftcard_amounts
              : undefined
          }
        >
          <ObsoletePurchaseDialog
            productData={dialogProductData}
            onClose={onCloseDialog}
            isVisible={isDialogVisible}
          />
        </ProductDataContextProvider>
      )}
    </div>
  )
}
